import React, { useRef, useCallback, useState } from "react";
import { Button } from "primereact/button";
import Webcam from "react-webcam";

const CapturePhoto = ({ onClose, onCapture }) => {
  const webcamRef = useRef(null);
  const [facingMode, setFacingMode] = useState("environment");
  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    onCapture(imageSrc);
    onClose(); // Cierra el diálogo al capturar
  }, [webcamRef, onCapture, onClose]);

  return (
    <div className="flex h-screen flex-col md:flex-row p-10 md:p-0 items-center justify-center w-full">
      <div>
        <Webcam
          audio={false}
          ref={webcamRef}
          height={720}
          width={1280}
          screenshotFormat="image/jpeg"
          videoConstraints={{
            height: 1080,
            width: 1920,
            facingMode: facingMode,
          }}
          style={{ width: "100%" }}
        />
        <Button className="p-button-rounded" onClick={capture}>
          Capturar Foto
        </Button>
        <Button
          className="p-button-rounded"
          onClick={() =>
            setFacingMode(facingMode === "user" ? "environment" : "user")
          }
        >
          Cambiar camara
        </Button>
        <Button className="p-button-rounded p-button-danger" onClick={onClose}>
          Cerrar
        </Button>
      </div>
    </div>
  );
};

export default CapturePhoto;
