import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useState } from "react";
import { Dialog } from "primereact/dialog";
import Loading from "../components/Loading";

const cardStyles = {
  imagen: {
    margin: "auto",
    width: "40%",
  },
};

const CrearNuevaContrasena = ({
  colorMode,
  isNewThemeLoaded,
  onNewThemeChange,
  location,
  handleCrearNuevaContrasena,
  handleButtonClickRecuperar,
  setDebeCrearContrasena,
  debeCrearContrasena,
  loading,
  setLoading,
}) => {
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("");
  const [oldPasWord, setOldPassword] = useState("");
  const [captchaValue, setCaptchaValue] = useState("");
  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };
  const handleNewPasswordConfirmationChange = (e) => {
    setNewPasswordConfirmation(e.target.value);
  };
  const handleOldPasswordChange = (e) => {
    setOldPassword(e.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      newPassword !== "" &&
      oldPasWord !== "" &&
      newPasswordConfirmation !== ""
    ) {
      if (e.nativeEvent.submitter.name === "crearButton") {
        e.preventDefault();
        if (!captchaValue) {
          alert("Por favor complete el CAPTCHA.");
          return;
        }
        if (newPassword !== newPasswordConfirmation) {
          alert("Las contraseñas no coinciden");
          return;
        }
        handleCrearNuevaContrasena(oldPasWord, newPassword);
      }
    } else {
      alert("Complete todos los campos");
    }
  };
  const handleCaptchaChange = (value) => {
    // Called when the user submits a successful reCAPTCHA response
    setCaptchaValue(value);
  };
  return (
    <>
      <>
        <form onSubmit={handleSubmit}>
          <div
            className="pages-body login-page flex flex-column"
            style={{ background: "none", backgroundColor: "#20ba84" }}
          >
            <div className="align-self-center mt-auto mb-auto">
              <div className="pages-panel card flex flex-column">
                <h1 className="pages-detail">Adquisiciones</h1>
                <img
                  src="assets/layout/images/Recurso-2.png"
                  alt=""
                  style={cardStyles.imagen}
                />

                <h5 className="pages-detail mb-6 px-6">
                  Detectamos que estás usando una contraseña temporal, por favor
                  crea una nueva contrseña
                </h5>

                <div className="input-panel flex flex-column px-3">
                  <div className="p-inputgroup mt-3 mb-3">
                    <span className="p-inputgroup-addon">
                      <i className="pi pi-lock"></i>
                    </span>
                    <span className="p-float-label">
                      <InputText
                        autoComplete="off"
                        type="password"
                        id="inputgroup2"
                        value={oldPasWord}
                        onChange={handleOldPasswordChange}
                      />
                      <label htmlFor="inputgroup2">
                        Contraseña Actual (Incluida en el correo)
                      </label>
                    </span>
                  </div>
                </div>
                <div className="input-panel flex flex-column px-3">
                  <div className="p-inputgroup mt-3 mb-3">
                    <span className="p-inputgroup-addon">
                      <i className="pi pi-lock"></i>
                    </span>
                    <span className="p-float-label">
                      <InputText
                        autoComplete="off"
                        type="password"
                        id="inputgroup2"
                        value={newPassword}
                        onChange={handleNewPasswordChange}
                      />
                      <label htmlFor="inputgroup2">Nueva Contraseña</label>
                    </span>
                  </div>
                </div>
                <div className="input-panel flex flex-column px-3">
                  <div className="p-inputgroup mt-3 mb-3">
                    <span className="p-inputgroup-addon">
                      <i className="pi pi-lock"></i>
                    </span>
                    <span className="p-float-label">
                      <InputText
                        autoComplete="off"
                        type="password"
                        id="inputgroup2"
                        value={newPasswordConfirmation}
                        onChange={handleNewPasswordConfirmationChange}
                      />
                      <label htmlFor="inputgroup2">
                        Confirme Nueva Contraseña
                      </label>
                    </span>
                  </div>
                </div>
                <div
                  className="p-inputgroup mt-1 mb-3"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {/* Add reCAPTCHA component */}
                  <ReCAPTCHA
                    sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                    onChange={handleCaptchaChange}
                  />
                </div>
                <Button
                  type="submit"
                  name="crearButton" // Add a name to the "Ingresar" button
                  className="login-button mb-3 px-3"
                  label="Crear Nueva Contraseña"
                  style={{ backgroundColor: "#20ba84" }}
                ></Button>
              </div>
            </div>
          </div>
        </form>
      </>
    </>
  );
};

export default CrearNuevaContrasena;
