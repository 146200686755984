import { ProgressSpinner } from 'primereact/progressspinner';

const Loading = () => {
    return (
        <div className="grid">
            <ProgressSpinner style={{ width: '50px', height: '50px', marginTop: '30%' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration="1s" aria-label="loading" />
        </div>
    );
};

export default Loading;
