import React, { useEffect, useRef, useState } from "react";
import QrScanner from "qr-scanner";
import "./QrStyles.css";
import QrFrame from "../assets/qr-frame.svg";

interface QrReaderProps {
  onScanSuccess: (result: string) => void;
}

const QrReader: React.FC<QrReaderProps> = ({ onScanSuccess }) => {
  const scanner = useRef<QrScanner>();
  const videoEl = useRef<HTMLVideoElement>(null);
  const qrBoxEl = useRef<HTMLDivElement>(null);
  const [qrOn, setQrOn] = useState<boolean>(true);
  const [cameraBlocked, setCameraBlocked] = useState<boolean>(false);

  const handleScanSuccess = (result: QrScanner.ScanResult) => {
    setQrOn(false);
    scanner.current?.stop();
    onScanSuccess(result.data);
  };

  const onScanFail = (err: string | Error) => {
    console.log("Scan error:", err);
  };

  // Iniciar el escáner después de verificar las cámaras disponibles
  const initializeScanner = async () => {
    try {
      const cameras = await QrScanner.listCameras();
      console.log(cameras);
      const backCamera = cameras.find((camera) =>
        camera.label.toLowerCase().includes("back")
      );

      if (videoEl.current && !scanner.current) {
        scanner.current = new QrScanner(videoEl.current, handleScanSuccess, {
          onDecodeError: onScanFail,
          preferredCamera: backCamera ? backCamera.id : "environment",
          highlightScanRegion: true,
          highlightCodeOutline: true,
          overlay: qrBoxEl.current || undefined,
        });

        await scanner.current.start();
        setQrOn(true);
        setCameraBlocked(false);
      }
    } catch (err) {
      console.error("Error accessing the camera:", err);
      setQrOn(false);
      setCameraBlocked(true);
    }
  };

  // Verificar permisos y disponibilidad al cargar el componente
  useEffect(() => {
    if (qrOn) {
      initializeScanner();
    }

    return () => {
      if (scanner.current) {
        scanner.current.stop();
        scanner.current = undefined;
      }
    };
  }, [qrOn]);

  useEffect(() => {
    if (!qrOn && cameraBlocked) {
      alert(
        "Camera is blocked or not accessible. Please allow camera in your browser permissions and Reload."
      );
    }
  }, [qrOn, cameraBlocked]);

  return (
    <div className="qr-reader">
      <video ref={videoEl}></video>
      <div ref={qrBoxEl} className="qr-box">
        <img
          src={QrFrame}
          alt="Qr Frame"
          width={256}
          height={256}
          className="qr-frame"
        />
      </div>
    </div>
  );
};

export default QrReader;
